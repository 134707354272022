@import "../../styles/main.sass";

.container {
    justify-self: center;
}

.container:hover {
    background-color: $hover-grey;
}

.link {
    box-shadow: 0 10px 10px -5px rgb(0 0 0 / 25%);
    border: none;
    border-radius: 10px;
    font-weight: bold;
    height: fit-content;
    padding: 1rem 1.5rem;
    position: relative;
    text-decoration: none;
    z-index: 1;
    @include media-medium {
        padding: 1.5rem 5.5rem;
    }
    span {
        color: $grey-900;
    }
}

.image {
    left: -28px;
    position: absolute;
    top: -28px;
    width: 30%;
    z-index: -1;
    @include media-medium {
        left: -43px;
        top: -43px;
        width: 28%;
    }
}
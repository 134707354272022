@import "../../styles/main.sass";

.testimonies_slider{
  display: none;
  @include media-large{
    display: block; // TODO: Hotfix
  }
}
.carrousel_image_wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  img {
    border-radius: 50%;
    margin-bottom: 16px;
    width: 30%;
  }
}
.container {
    background: transparent;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
  }

.slick-vertical .slick-slide {
  height: 240px;
}
.slick-list{
  height: 460px;
}
.slick-dots{
  bottom: -50px;
}
.slick-arrow {
  background: transparent;
}

.slick-prev {
  left: -43px !important;
  z-index: 100;
}
.slick-next {
  right: -50px !important;
  z-index: 100;
}

.slick-dots li.slick-active button:before {
  color: $white-900;
  font-size: 25px;
}
  
.slick-dots li button:before  {
  color: black;
  font-size: 12px;
  opacity: 0.6;
}

.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 40px;
  opacity: 0.6;
}

.slide_content {
  align-items: space-between;
  color: $white-900;
  display: flex!important;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  height: 330px;
  justify-content: space-between;
  opacity: 0.5;
  .carrousel_description{
    padding: 16px;
  }
  b {
    display: block; 
  }
}
.slick-center{
  .slide_content{
    opacity: 1;
    .carrousel_description{
      background-color: $white-900;
      border-radius: 16px;
      color: $grey-900;
      position: relative;
      &:after{
        background-color: $white-900;
        border-radius: 4px;
        bottom: 10;
        content: '';
        display: block;
        height: 24px;
        left: 0;
        margin-left: auto;
        margin-right: auto ;
        position: absolute;
        right: 0;
        transform: rotate(45deg);
        width: 24px;

      }
    }
  }
  
}
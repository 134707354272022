
@import "../../styles/main.sass";

.container {
  align-items: center;
  background-color: $lightblue-700;
  box-sizing: border-box;
  height: fit-content;
  display: flex;
  min-height: 50rem;
  justify-content: space-evenly;
  flex-direction: column;
  @include media-medium {
    padding: 2rem 0
  }
  @include media-xlarge {
    flex-direction: row;
    padding: 4rem 0;
  }
  h1 {
    color: $grey-900;
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: center;
  }
}

.form_ebook {
  margin-top: 4rem;
}

.image_container {
  @include media-medium {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image {
  width: 100%;
  @include media-medium {
    justify-self: center;
  }
}

main {
  padding-top: 5rem;
}
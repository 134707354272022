@import "../../styles/main.sass";

.navBar {
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	display: grid;
	flex-direction: row;
	grid-template-columns: 1fr 1fr 1fr;
	height: 90px;
	justify-content: space-between;
	padding: 0.5rem;
	width: 100%;
	@include wrapper;
	@include media-medium{
		grid-template-columns: 2fr 1fr 90px;
		
	}
	&.hide{
		grid-template-columns: 32px 1fr 32px;
		grid-template-rows: 80px 1fr 1fr;
		height: 100vh;
		.left_content{
			.list_wrapper{
				grid-column: 2/3;
				grid-row: 2/3;
			}
		}
		.lang_container{
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			grid-column: 1/4;
			grid-row: 3/4;
			justify-content: space-evenly;
			.lang_title{
				font-size: $font-size-006;
				margin-bottom: 16px;
				text-align: center;
				width: 100%;
			}
		}
	}

	&.show{
		grid-template-columns: 32px 1fr 32px;
		grid-template-rows: 80px;
		@include media-medium{
			grid-template-columns: 48px 1fr 260px;
		}
		@include media-large{
			grid-template-columns: 200px 1fr 260px;
		}
		.list_wrapper{
			display: none;
			@include media-medium{
				display: flex;
			}
		}

		.lang_container{
			display: none;
			@include media-medium{
				display: flex;
				grid-column: 3/4;
				grid-row: 1/2;
				width: 82px;
			}
			.lang_title{
				display: none;
			}
		}
	}
	.menu_icon{
		align-items: center;
		box-sizing: border-box;
		display: flex;
		font-size: 24px;
		grid-column: 3/4;
		grid-row: 1/2;
		justify-content: center;
		@include media-medium{
			display: none;
		}
	}


		.logo_wrapper {
			align-items: center;
			cursor: pointer;
			display: flex;
			width: inherit;

			.image {
				display: none;
				@include media-large{
					display: block;
					margin-right: 8px;
					width: 180px;
				}
			}
			.iso{
				aspect-ratio: 1/1;
				display: flex;
				width: 32px;
				@include media-large{
					display: none;
				}
			}
		}
		
		.list_wrapper {
			display: flex;
			grid-column: 2/3;
			grid-row: 2/3;
			width: 100%;
			@include media-medium{
				grid-row: 1/2;
			}
		}
	.actions{
		align-items: center;
		display: flex;
		justify-self: center;
		@include media-medium{
			grid-column: 3/4;
			grid-row: 1/2;
			justify-self: flex-end;
		}
		a:first-child{ // TODO: super hotfix
			align-items: center;
			display: flex;
			justify-content: center;
			min-width: 100px;
		}
	}
}

.list {
	flex-direction: row;
	justify-content: space-between;
	list-style: none;
	width: 100%;
	@include media-medium{
		display: flex;
		gap: 24px;
		justify-content: flex-start;
	}
	.list_element {
		cursor: pointer;
		margin: 24px 0;
		text-align: end;
		@include media-medium{
			margin: 0;
		}
		.text {
			color: $grey-700;
			font-size: $font-size-004;
			@include media-medium{
				font-size: $font-size-005;
			}
		}
	}
}












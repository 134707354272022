@import "../../../styles/main.sass";
.container {
    align-items: center;
    background-color: $white-900;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    max-height: 91rem;
    width: 100%;
    @include media-medium {
        padding: 2rem 0;
    }
    @include media-large {
        padding: 5rem 0;
    }
}

.content_wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include media-xsmall{
        justify-content: space-between;
    }
    .header{
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        h5{
            color: $grey-700;
            text-align: center;
            width: 100%;
        }
        h1{
            color: $grey-700;
			font-size: 32px;
			text-align: center;
			width: 60%;
			z-index: 2;

			@include media-xsmall{
				width: 100%;
			}
			@include media-medium{
				@include Headline__01-Regular;
				width: 80%;
			}
			b{
                display: inline-flex;
                display: inline-block;
                text-transform: uppercase;
			}
        }
    }
    .featlist {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        list-style: none;
        margin: 24px 0;
        color: $grey-700;
        width: 100%;
        align-items: center;
        @include media-medium {
            width: 80%;
            margin: 0 auto;
        }
        @include media-large {
            column-gap: 3rem;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
        }
        .item {
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 80%;
            @include media-large{
                flex: 25%;
                width: 60%;
            }
            .value{
                text-align: center;
                font-size: 38px;
                color: $grey-700;
                @include media-medium {
                    font-size: 48px;
                }
            }
            .title{
                margin: 16px 0;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
            }
            .description{
                text-align: center;
                @include Body__01-Regular;
                color: $grey-700;
            }
        }
    }
    .link {
        text-decoration: none; 
        color: inherit; 
    }
}



@import "../../../styles/main.sass";

.container {
    align-items: center;
    display: flex;
    background-color: $lightblue-700;
    justify-content: center;
    padding-bottom: 1rem;
    width: 100%;
    @include media-medium {
        padding-top: 4rem;
        padding-bottom: 5rem;
    }
    @include media-large {
        padding: 5rem 0;
    }
}

.content_wrapper {
    align-items: center;
    display: grid;
    gap: 4rem;
    height: 18rem;
    justify-content: center;
    width: 80%;
}

h2 {
    color: $white-900;
    text-align: center;
	@include Headline__02-Medium;
}
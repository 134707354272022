@import "../../styles/main.sass";


.form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 40px 5px 40px;
    margin-right: 20px;
    width: 400px;
    margin-left: 20px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.25);

}



.form_button_wrapper {
    display: flex;
    justify-content: center;
    
}

.form_button {
    width: 250px;
    margin-top: 10px;
    border: 1px solid #4c2d86;
    border-radius: 30px;
    background-color: #4c2d86;
}

@include media-medium {
    .form_container {
        width: 100%;
    }
    .form_wrapper {
        margin-right: 65px;
    }
    .form_button {
        margin-left: 65px;
    }
    
}


@import "../../../styles/main.sass";

.abraham_logo {
    width: 100%;
}

.alcazar_logo {
    height: 75%;
    width: 66%;
    @include media-medium {
        height: 50%;
        width: 88%;
    }
    @include media-large {
        height: 70%;
        width: 60%;
    }
}

.container {
    align-items: center;
    background-color: $white-900;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.educare_logo {
    width: 50%;
}

.futrono_logo {
    width: 90%;
}

.logos_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    @include media-large {
        grid-template-columns: 30% 70%;
    }
}

.images_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    width: 100%;
    @include media-medium{
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-large {
        grid-template-columns: repeat(4, 1fr);
    }
}

.innova_schools_logo {
    width: 50%;
}

.logo_wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.logo_img {
    width: 65%;
}

.description_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
    strong {
        font-size: 24px;  
        padding-left: 0.5rem;
        @include media-medium {
            padding: 1rem;
        }
        @include media-large {
            font-size: 32px;
            padding: 0;
        }      
    }
    @include media-large {
        padding-left: 6rem;
    }
}

.polygon_triangle {
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 50px solid #DC5E38;  
    @include media-medium {
        border-top: 140px solid transparent;
        border-bottom: 140px solid transparent;
        border-left: 60px solid #DC5E38;
    }
}

.san_agustin_logo {
    width: 55%;
}

.title {
    color: $grey-700;
}


@import "../../../styles/main.sass";

.container {
    align-items: center;
    background-color: $white-900;
    display: flex;
    height: fit-content;
    justify-content: center;
    left: 0;
    min-height: 20rem;
    padding: 0 1rem;
    top: 0;
    width: 100%;
    @include media-xlarge {
        padding: 0;
    }
}

.content_wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    @include wrapper;
    @include media-medium{
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    h5{
        color: $lightblue-700;
    }
    h2{
        color: $lightblue-700;
        font-size: 32px;
        @include media-medium{
            @include Headline__01-Regular;
            margin-bottom: 32px;
        }
        b{
            display: inline-block;
        }
    }
    img.halfCircle{
       position: absolute;
       width: 80px;
       z-index: -1;
       transform:scaleX(-1);
       right: -1px;
       top: -4%;
       @include media-medium {
        right: 1%;
        top: -3%;
       }
       @include media-xlarge {
        right: 4%;
        top: -7%;
       }
    }
    .info_steps {
        display: flex;
        flex-direction: column;
        .step_right {
            align-items: right;
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 24px 0px;
            @include media-large{
                flex-direction: row;
            }
            &:nth-child(2n) {
                flex-direction: column-reverse;
                @include media-large{
                    flex-direction: row;
                }
            }
            .step_image {
                width: 100%;
                @include media-large{
                    width: 60%;
                }
            }
            .step_description {
                @include Body__01-Regular;
                color: $grey-700;
                text-align: right;
            }
            .step_title {
                text-align: right;
            }
        }

        .step_left {
            align-items: left;
            display: flex;
            flex-direction: row-reverse;
            .step_description {
                @include Body__01-Regular;
                color: $grey-700;
                text-align: left;
            }
            .step_title {
                text-align: left;
            }
        }
    }
}

.text_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.video {
    align-self: center;
    aspect-ratio: 560/315;
    border: 10px solid $white-900;
    border-radius: 16px;
    box-sizing: border-box;
    height: auto;
    margin-top: 32px;
    width: 100%;
    @include media-xsmall {
        margin-top: 0;
    }
}
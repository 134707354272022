@import "../../styles/main.sass";

.container {
  border-radius: 0.3125rem;
  display: flex;
  flex-wrap: nowrap;
  height: auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
  column-gap: 1rem;
  width: fit-content;
}

.error {
  background-color: $red-20;
}

.icon_wrapper {
  width: auto;
}

.info {
  background-color: $violet-100;
}

.success {
  background-color: $emerald-50;
}

.warning {
  background-color: $yellow-100;
}

.text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.large {
  padding: 0.5rem 1rem;
}

.small {
  padding: 0.1rem 1rem;
}

.warning {
  border: 1px solid #ffe400;
}

@import "../../styles/main.sass";
header {
	align-items: center;
	box-shadow: 0 10px 10px -5px rgb(0 0 0 / 25%);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: center;
	position: fixed;
	width: 100%;
	z-index: 4;
	@include media-xsmall{
		background-color: $white-900;
		display: flex;
	}
	a{
		cursor: pointer;
		text-decoration: none;
	}
}
@import "../../styles/main.sass";

.button_link{
	align-items: center;
	background: none;
	border: 0px;
	color: $white-900;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	
	gap: 8px;
	margin-bottom: 32px;
	.button_icon{
		font-size: 24px;
	}
	.button_text{
		@include Button__01-Medium;
	}
	&:hover{
		color: $violet-500;
	}
}

@import "../../styles/main.sass";
.footer {
	align-items: center;
	box-sizing: border-box;
	display: flex; 
	flex-direction: column;
	justify-content: center;
	padding: 3rem 1rem;
	width: 100%;
	@include media-large {
		padding: 5rem 1rem;
	}
	.wrapper {
		@include wrapper;
		display: flex;
		flex-direction: column;
		gap: 24px;
		@include media-medium{
			flex-direction: column;
			padding-bottom: 3.5rem;
		}
	}
	a {
		cursor: pointer;
		text-decoration: none;
	}
	
}

.icons_wrapper,
.text_wrapper {
	align-items: center;
	display: flex;
}

.icons_wrapper {
	justify-content: space-around;
	margin-bottom: 48px;
	margin-top: 24px;
	width: 100%;
	@include media-xsmall {
		gap: 24px;
		width: 100%;
	}
	@include media-medium {
		gap: 0;
		justify-content: space-between;
		margin: 0;
		width: 40%;
	}
	@include media-large {
		width: 60%;
	}
}

.image {
	width: 10rem;
	@include media-large {
		width: 16rem;
	}
}

.image_wrapper {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
	width: 100%;
	@include media-medium{
		justify-content: flex-start;
		margin: 0;
	}
}

.info_wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;
	width: 100%;
	@include media-medium {
		flex-direction: row;
		width: 100%;
	}
	@include media-large {
		justify-content: space-between;
	}
}

.list_wrapper {
	width: 100%;
	@include media-xsmall {
		width: 45%;
	}
	@include media-medium {
		width: 100%;
	}
}

.navigation_wrapper {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include media-medium{
		align-items: center;
		flex-direction: row;
	}
}

.right_content {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	@include media-medium {
		align-items: flex-end;
		flex-direction: column;
		gap: 24px;
	}
	@include media-large {
		align-items: flex-end;
		flex-direction: column;
		width: fit-content;
	}
}

@import "../../styles/main.sass";

.container {
    background-color: #E1F7F6;
    border-radius: 15px;
    color: #585B5B;
    display: flex;
    height: auto;
    padding: 2rem 1rem 4rem 1rem;
    margin: 2rem 0;
    position: relative;
    width: 70%;
    z-index: 1;
    @include media-medium {
        padding: 3rem 2rem 4rem 2rem;
    }
    @include media-large {
        margin: 4rem 0;
        width: 50%;
    }
}

.divider_text {
    color: #4C2D86;
}

.button_wrapper {
    display: flex;
    align-items: center;
    button {
        background-color: #E1F7F6;
        color: #00C0BB;
        cursor: pointer;
        border: none;
        font-size: 60px;
        height: auto;
        img {
            width: 0.8rem;
            @include media-medium {
                width: 1rem;
            }
            @include media-large {
                width: 1.5rem;
            }
        }
    }
}

.slick-slider {
    position: absolute;
}

.slider {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.text_wrapper {
    padding: 1.5rem;
    width: 100%;
    @include media-medium {
        float: right;
        width: 80%; 
    }
    @include media-large{
        padding-right: 4rem;
    }
    p {
        font-size: 18px;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 1;
        @include media-medium {
            width: 85%;
        }
        @include media-large {
            width: 60%;
        }
        &:after,&:before {
            font-family:sans-serif;
            font-size: 110px;
            letter-spacing: -10px;
            opacity: 0.2;
            position: absolute;
            z-index: 0;
          }
          &:before {
            content: "‘‘";
            left: 0%;
            top: -64px;
            @include media-medium {
                left: -8%;
            }
            @include media-xlarge {
                left: -5%;
            }
          }
    }
    hr {
        color: $violet-700;
        margin: 1rem 0;
    }
    h4 {
        text-align: right;
        font-weight: 200;
        margin: 0;
    }
    .role_text {
        font-weight: bold;
        text-align: right;
    }
}

h2 {
    color: $lightblue-700;
    font-size: 32px;
}

.semi_circle_icon {
    display: none;
    @include media-large {
        display: block;
        left: -74px;
        position: absolute;
        top: -74px;
        z-index: 0;
    }
}

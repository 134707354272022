$lightblue-900: #0a7f7d;
$lightblue-700: #17bebb;
$lightblue-500: #45cbc9;
$lightblue-300: #74d8d6;
$lightblue-100: #a2e5e4;
$lightblue-50: #def3f4;
$violet-900: #341966;
$violet-700: #4c2d86;
$violet-500: #6441a5;
$violet-300: #a78cd9;
$violet-100: #d2c2f0;
$yellow-900: #e5c500;
$yellow-700: #ffe400;
$yellow-500: #ffe944;
$yellow-300: #ffef7a;
$yellow-100: #fff4a8;
$grey-900: #2f3838;
$grey-700: #585b5b;
$grey-500: #898a89;
$grey-300: #a9b2b2;
$grey-100: #e1e5e5;
$emerald-800: #065f46;
$emerald-500: #10b981;
$emerald-400: #34d399;
$emerald-200: #a7f3d0;
$emerald-50: #ecfdf5;
$red-900: #cc2814;
$red-700: #f24530;
$red-500: #ff5f4d;
$red-200: #ff8577;
$red-50: #feaaa1;
$red-20: #ffedee;
$pink-700: #ff2f6d;
$pink-500: #ff6794;
$pink-300: #ffa7c1;
$pink-100: #ffcfde;
$white-900: #ffffff;
$hover-grey: #f1f0f0;

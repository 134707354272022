@import "../../../styles/main.sass";

.container {
	align-items: center;
	background-color: white;
	border-radius: 16px;
	display: flex;
	height: 200px;
	justify-content: space-around;
	width: 100%;
}
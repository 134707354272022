@import "../../styles/main.sass";

.container {
    align-items: center;
    background-color: $white-900;
    background-image: url(../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 40%, $white-900 40%, $white-900 100%);
    background-position: 36rem -25rem, 0 0;
    background-size: 330% 83rem;
    box-sizing: border-box;
    display: flex;
    height: fit-content;
    justify-content: center;
    left: 0;
    min-height: 50rem;
    padding-bottom: 64px;
    padding-top: 0px;
    top: 0;
    width: 100%;
    flex-direction: column;
    width: -webkit-fill-available;
}
@import "../../../styles/main.sass";

.container{
  background-color: $white-900;
  border-radius: 16px;
  color: $grey-700;
  display: flex;
  flex-direction: column;
  height: 450px;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  @include media-medium{
    height: 450px;
    width: 420px;
  }
  .title{
    @include Headline__04-Bold;
    color: $violet-700;
    margin-bottom: 8px;
  }
  .tier_title{
    @include Headline__04-Bold;
    color: $red-700;
    margin-bottom: 16px;
  }
  .tier_description{
    @include Subtitle__02-Bold;
    margin: 8px 0;
    &_question{
      color: $violet-700;
      margin: 8px 0;
    }
  }
  .tier_features_list{
    ul{
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
      li{
        display: flex;
        gap: 8px;
        justify-content: space-between;
        list-style: none;
        @include Body__02-Regular;
        &::after{
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOC4yMDA4IDcuOTczN0MyOC43ODY2IDguNTU5NDkgMjguNzg2NiA5LjUwOTI0IDI4LjIwMDggMTAuMDk1TDE0LjIwMDggMjQuMDk1QzEzLjYxNSAyNC42ODA4IDEyLjY2NTMgMjQuNjgwOCAxMi4wNzk1IDI0LjA5NUw1LjA3OTQ4IDE3LjA5NUM0LjQ5MzY5IDE2LjUwOTIgNC40OTM2OSAxNS41NTk1IDUuMDc5NDggMTQuOTczN0M1LjY2NTI2IDE0LjM4NzkgNi42MTUwMSAxNC4zODc5IDcuMjAwOCAxNC45NzM3TDEzLjE0MDEgMjAuOTEzTDI2LjA3OTUgNy45NzM3QzI2LjY2NTMgNy4zODc5MiAyNy42MTUgNy4zODc5MiAyOC4yMDA4IDcuOTczN1oiIGZpbGw9IiMxMGI5ODEiLz4KPC9zdmc+Cg==);
          background-size: cover;
          content: "";
          display: block;
          max-height: 16px;
          min-width: 16px
        }
      }
    }
  }
  .actions{
    display: flex;
    justify-content: center;
    .button_link {
      align-self: center;
      background-color:$violet-700;
      border-radius: 100px;
      color: $white-900;
      display: flex;
      font-weight: bold;
      margin: 24px 0 0 0;
      padding: 12px 30px;
      text-decoration: none;
  }
  
  .button_link:hover {
      background-color: $violet-500;
      color: $white-900;
      cursor: pointer;
      transition: all ease-in 0.3s;
  }
  }
}

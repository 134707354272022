@import "../../styles/main.sass";

.list {
	justify-content: space-around;
	list-style: none;
	width: 100%;
	@include media-medium{
		display: flex;
		flex-direction: row;
	}
	@include media-large {
		justify-content: space-between;
	}
	.list_element {
		cursor: pointer;
		margin: 24px 0;
		@include media-medium{
			margin: 0;
		}
		.text {
			color: $grey-700;
			font-size: $font-size-006;
			@include media-medium{
				font-size: $font-size-005;
			}
		}
	}
}

ul {
	margin-bottom: 0;
}





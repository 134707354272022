@import "../../../styles/main.sass";
.container {
    align-items: center;
    background-color: $violet-500;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-medium {
        padding: 4rem 0;
    }
    @include media-large {
        padding: 5rem 0;
    }
}

.content_wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    ul {
        width: 80%;
        margin: 0 auto;
        justify-self: center;
    }
    h2 {
        font-size: 48px;
        text-align: center;
        width: 90%;
        @include Headline__02-Medium;
        @include media-medium{
            width: 80%;
        }
    }

    .featlist {
        align-items: center;
        color: $white-900;
        display: grid;
        grid-template-columns: 1fr;
        list-style: none;
        gap: 2rem;
        margin: 0 auto;
        width: 80%;
        @include media-medium {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }
        @include media-xlarge {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .featlist_eng {
        align-items: center;
        color: $white-900;
        display: grid;
        grid-template-columns: 1fr;
        list-style: none;
        gap: 2rem;
        margin: 0 auto;
        width: 80%;
        @include media-medium {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
            width: 100%;
            margin-left: 460px;
        }
        @include media-xlarge {
            grid-template-columns: repeat(5, 1fr);
            
        }
    }

    .link {
        text-decoration: none; 
        color: inherit; 
    }
}



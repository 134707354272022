.button_link {
	background-color: #ffffff;
	border: 2px solid #17bebc;
	border-radius: 100px;
	box-shadow: 0 10px 10px -5px rgb(0 0 0 / 25%);
	color: #17bebc;
	padding: 12px 30px;
	text-decoration: none;
}

.button_link:hover {
	background-color: #17bebc;
	color: #ffffff;
}

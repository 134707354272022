@import "../../../styles/main.sass";

.container {
    align-items: center;
    background-color: $white-900;
    background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 40%, $lightblue-700 40%, $lightblue-700 100%);
    background-position: 36rem -25rem, 0 0;
    background-size: 330% 83rem;
    box-sizing: border-box;
    display: flex;
    height: fit-content;
    justify-content: center;
    left: 0;
    min-height: 50rem;
    padding-bottom: 64px;
    padding-top: 280px;
    margin-top: -64px;
    top: 0;
    width: 100%;
    flex-direction: column;
    @include media-xsmall{
        background-color: $white-900;
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 22%, $lightblue-700 22%, $lightblue-700 100%);
        background-position: -12rem -9rem, 0 0;
        background-size: 51rem auto;
        padding-top: 228px;
    }
    @include media-small{
        background-color: $white-900;
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 22%, $lightblue-700 22%, $lightblue-700 100%);
        background-position: -26rem -27rem, 0 0;
        background-size: 94rem auto;
        height: fit-content;
        padding-top: 190px;
    }
    @include media-medium{
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 55%, $lightblue-700 55%, $lightblue-700 100%);
        background-position: 45% 50%, 0 0;
        background-size: 245%;
        padding-top: 240px;
    }
    @include media-large{
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 30%, $lightblue-700 30%, $lightblue-700 100%);
        background-position: 45% 63%, 0 0;
        background-size: 245%;
        height: fit-content;
        min-height: 50rem;
        padding-top: 240px;
    }
    @include media-xlarge{
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 50%, $lightblue-700 50%, $lightblue-700 100%);
    }
    @include media-xxlarge{
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 50%, $lightblue-700 50%, $lightblue-700 100%);
        background-position: 50% 51%, 0 0;
        background-size: 245%;
    }
    @include media-xxxlarge{
        background-image: url(../../../assets/background/bg-1.svg),linear-gradient(180deg,$white-900 0%, $white-900 50%, $lightblue-700 50%, $lightblue-700 100%);
        background-position: 50% 51%, 0 0;
        background-size: 266%;
    }

}

.content_wrapper {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-between;
    
    @include wrapper;
    @include media-medium{
        flex-direction: row;
        height: 100%;
    }
    h1{
        font-size: 32px;
        width: 100%;
        @include media-medium{
            @include Headline__01-Regular;
        }
    }
    h5{
        font-size: 20px;
        color: $white-900;
    }
    p{
        font-size: 16px;
        @include media-xsmall{
            @include Body__01-Regular ;
        }
    }
}

.image {
    width: 100%;
    @include media-medium{
        width: 60%;
    }
}

.text_wrapper {
    color: $white-900;
    width: 100%;

    @include media-medium{
        width: 50%;
    }
}

.title {
    padding: 0.5rem 0;
    color: $white-900;
}
.mailus{
    padding-top: 32px;
}

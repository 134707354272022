@import "../../styles/main.sass";

.divider{
  display: block;
  width: 100%;
  height: 148px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  &.reverse{
    transform: scaleY(-1);
  }
  &.green{
    background-image: url('../../assets/backgrounds/small-wave-green.svg');
    @include media-large{
      background-image: url('../../assets/backgrounds/big-wave-green.svg');
    }
  }
  &.violet{
    background-image: url('../../assets/backgrounds/small-wave-violet.svg');
    @include media-large{
      background-image: url('../../assets/backgrounds/big-wave-violet.svg');
    }
  }
  &.mix{
    background-image: url('../../assets/backgrounds/small-wave-mix.svg');
    @include media-large{
      background-image: url('../../assets/backgrounds/big-wave-mix.svg');
    }
  }
}

@import "../../../styles/main.sass";

.container {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
	box-shadow: 0 10px 10px -5px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    min-height: 24rem;
    justify-content: center;
    padding: 0 1rem;
    img {
        aspect-ratio: 1;
        width: 50%;
    }
}

@include media-medium {
    .container {
        min-height: 28rem;
    }
}

@include media-large {
    .container {
        min-height: 24rem;
    }
}

.container:hover {
  background-color: $hover-grey;
}

.link {
    align-items: center;
    color: $violet-500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    &:visited {
        color: $violet-700;
      }
    &:hover {
        color: $violet-700;
        text-decoration: none;
    }
    h5 {
        @include Subtitle__02-Bold;
        color: $violet-700;
    }
}

.description {
    text-align: center;
    font-size: 16px;
}

.title {
    margin-top: -12px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
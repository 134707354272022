@import "../../styles/main.sass";

.container {
    color: $lightblue-700;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @include media-medium {
        color: $white-900;
    }
    @include media-xlarge {
        width: 50%;
    }
}

.form_ebook {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo_wrapper {
    display: flex;
    gap: 1rem;
    img {
        border-radius: 10px;
        width: 30%;
        @include media-medium {
            width: 22%;
        }
        @include media-xlarge {
            width: 15%;
        }
    }
    p {
        align-self: center;
        color: $white-900;
        @include Body__01-Regular;
    }
}

.main_awards {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    width: 80%;
}

.secondary_awards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    h3 {
        color: $white-900;
        text-align: center;
        @include Headline__03-Bold 
    }
    div {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-wrap: wrap;
        height: auto;
        justify-content: space-evenly;
        padding-top: 2rem;
        @include media-medium {
            padding-top: 0;
        }
        .startup_img, .started_img {
            width: 40%; 
            @include media-medium {
                width: 25%;
            }
            @include media-xlarge {
                width: 20%;
            }
        }
        .elab_img {
            width: 35%;
            @include media-medium {
                width: 28%;
            }
            @include media-xlarge {
                width: 20%;
            }
        }
    }
}


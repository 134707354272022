@import "../../styles/main.sass";

.lang_list{
  @include Subtitle__02-Bold;
  margin: 0px 8px;
  text-transform: uppercase;
  a:active,a.active{
    color: $violet-700; 
  }
  a{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .flag{
      font-size: 32px;
      @include media-medium{
        font-size: 24px;
      }
    }
    .language{
      font-size: $font-size-006;
      font-weight: $font-weight-002;
      text-transform: capitalize;
      @include media-medium {
        display: none;
      }
    }
  }
}